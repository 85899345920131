<template>
  <!--  <a-spin :spinning="spinning" size="small">-->
  <div class="issGridWrap mt-20">
    <!--  粉丝标签  -->
    <div class="left">
      <div class="titleTab">
        <div class="tabLeft">标签</div>
        <div class="tabRight">
          <!--       需要做动态样式   -->
          <a-button
            type="primary"
            @click="handleClickSueess"
            :loading="loading"
          >
            确认
          </a-button>
        </div>
      </div>
      <div class="tabContent">
        <div
          v-for="item in wxTagList"
          :key="item.id"
          class="tabItem cursor-p"
          :class="{ selecItemClass: item.id === itemId }"
          @click="handleItem(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="right">
      <div class="refresh-btn flex">
        <div class="time">最新更新时间：{{ lastUpdateTime }}</div>

        <div class="updata" @click="updateContactFun">
          <SyncOutlined class="mr-10" v-if="!loadingT" />
          <a-spin class="mr-10" size="small" v-else />
          <span>更新粉丝状态</span>
        </div>
      </div>

      <grid
        ref="gridRef"
        :allow-selection="true"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="isSeach"
        :url-params="{
          blackContact: false,
          appId: $store.state.account.appId,
        }"
        :btn-operation="btnOperation"
        :scroll="{ x: 1000, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>

        <template #wxTagList="{ record }">
          <!--        <div v-if="record.tagIdList?.length>0" @click="handletagIdQuery(record)">-->
          <!--          标签-->
          <!--        </div>-->

          <a-dropdown
            placement="bottomCenter"
            v-if="record.wxTagList?.length > 0"
          >
            <div class="contact-icon cursor-p">标签<DownOutlined /></div>

            <template #overlay>
              <a-menu @click="handleClickByMenu">
                <div class="menuClass">
                  <a-menu-item
                    v-for="item in record.wxTagList"
                    :key="item.id"
                    class="menuItem"
                  >
                    <a-tag closable @close="closeTab(item, record)">
                      {{ item.name }}
                    </a-tag>
                  </a-menu-item>
                </div>
              </a-menu>
            </template>
          </a-dropdown>
          <div v-else>-</div>
        </template>

        <template #tagIdList="{ record }">
          <div v-if="record.wxTagList?.length > 0">
            {{ record.wxTagList?.length }}
          </div>
          <div v-else>0</div>
        </template>

        <template #subscribe="{ record }">
          <div v-if="record.subscribe === 0">未关注</div>
          <div v-if="record.subscribe === 1">已关注</div>
        </template>
      </grid>
    </div>
  </div>
  <!--  </a-spin>-->

  <!-- 发送成功弹框 -->
  <div class="modalClass" ref="container">
    <a-modal
      v-model:visible="successModalVisible"
      width="540px"
      :closable="false"
      :centered="true"
      :getContainer="() => container"
    >
      <div>以下粉丝用户已超过20个标签，标记标签失败：</div>
      <div class="errorList">
        <div class="opendId" v-for="(item, index) in errorList" :key="item.id">
          {{ item.openId }}
          <span v-if="index < errorList.length - 1">，</span>
        </div>
      </div>

      <template #footer>
        <a-space>
          <a-button type="primary" @click="successModalVisible = false">
            确认
          </a-button>
        </a-space>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs, watch, createVNode } from 'vue';
import Grid from '@/components/grid';
import * as icons from '@ant-design/icons-vue';
import wechatApi from '@/api/wechatServer';
import Operation from '@/components/operation';
import {
  Dropdown,
  Menu,
  message,
  // Spin,
  Tag,
  Modal,
} from 'ant-design-vue';
import { useStore } from 'vuex';
import { SyncOutlined, DownOutlined } from '@ant-design/icons-vue';

export default {
  name: 'followed',
  components: {
    Grid,
    Operation,
    ADropdown: Dropdown,
    AMenu: Menu,
    SyncOutlined,
    // ASpin: Spin,
    ATag: Tag,
    AModal: Modal,
    DownOutlined,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isSeach: {
      type: Object,
      default: () => ({}),
    },
    lastUpdateTime: {
      type: String,
      default: '',
    },
    loadingT: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = useStore();
    const gridRef = ref();
    const container = ref();
    const state = reactive({
      isSeach: {},
      lastUpdateTime: '',
      showTab: false,
      tabId: '',
      appId: store.state.account.appId,
      wxTagList: [],
      itemId: '',
      tagId: '',
      selectedRowKeys: [],
      loading: false,
      spinning: false,
      loadingT: false,
      errorList: [],
      successModalVisible: false,
    });

    const handleClickSueess = () => {
      if (state.selectedRowKeys.length > 20) {
        message.success('正在给用户标记标签，预计需要15秒，请耐心等候...');
      }

      if (state.selectedRowKeys.length === 0) {
        return message.error('请先勾选粉丝用户!');
      }
      if (state.tagId === '') {
        return message.error('请先选择标签!');
      }

      state.loading = true;
      state.spinning = true;
      context.emit('fnGrid', state.spinning);
      //  最多只能40个粉丝
      let selecItemList = [];
      state.wxTagList.map(i => {
        if (i.color) {
          selecItemList.push(i);
        }
      });
      let data = {
        appId: state.appId,
        idList: state.selectedRowKeys,
        tagId: state.tagId,
      };
      wechatApi
        .membersBatchTagging({}, data)
        .then(() => {})
        .catch(err => {
          console.log('error', err);
        });
      // 0.8秒 后获取打标签结果
      state.timer = setInterval(() => {
        getResult();
      }, 800);
    };

    const getResult = () => {
      wechatApi
        .membersBatchResult({}, state.appId)
        .then(res => {
          if (res) {
            if (res.requestStatus === 'LOADING') {
              // debugger
              // if (state.selectedRowKeys.length > 20) {
              //   message.success('正在给用户标记标签，预计需要15秒，请耐心等候...')
              // }
              console.log('正在给用户标记标签');
            }

            if (res.requestStatus === 'OVER') {
              // debugger
              // state.errorList =  gridRef.value.getDataSource(),
              //   console.log('state.errorList', state.errorList)
              // state.successModalVisible = true
              clearInterval(state.timer);
              if (res.updateStatus === 'SUCCESS') {
                // console.log('res 标签结果', res)
                // 关闭Loading状态
                state.loading = false;
                state.spinning = false;
                context.emit('fnGrid', state.spinning);
                message.success('标记标签操作成功');
                gridRef.value.refreshGrid();
                (state.tagId = ''), (state.itemId = '');
              } else if (res.updateStatus === 'ERROR') {
                clearInterval(state.timer);
                // 展示打标签失败问题数据 errorContact  ，弹窗展示
                state.errorList = res.errorContact;
                state.successModalVisible = true;

                state.loading = false;
                state.spinning = false;
                context.emit('fnGrid', state.spinning);
              }
            }
          }
        })
        .catch(err => {
          clearInterval(state.timer);
          console.log('error', err);
          //  关闭loading
          state.loading = false;
          state.spinning = false;
          context.emit('fnGrid', state.spinning);
        });
    };
    const getWxTagListFun = () => {
      wechatApi.getWxTagList('', state.appId).then(res => {
        if (res) {
          console.log('res 标签', res);
          // res.map((item)=>{
          //   item.color = false
          // })
          state.wxTagList = res;
        }
      });
    };
    const updateList = () => {
      gridRef.value.refreshGrid();
      getWxTagListFun();
    };
    //  gridRef.value.selectedRowKeys  selectedRowKeys
    watch(
      () => gridRef?.value?.selectedRowKeys,
      value => {
        if (value.length > 50) {
          return message.error('最多可勾选50个粉丝打标签');
        } else {
          // context.emit('fnGrid', value);
          state.selectedRowKeys = value;
        }
      }
    );

    watch(
      () => props.isSeach,
      value => {
        state.isSeach = value;
      }
    );
    watch(
      () => props.lastUpdateTime,
      value => {
        if (value) {
          state.lastUpdateTime = value;
        }
      }
    );

    watch(
      () => props.loadingT,
      value => {
        if (value) {
          state.loadingT = value;
        }
      }
    );
    onMounted(() => {
      getWxTagListFun();
    });

    return {
      ...toRefs(state),
      getWxTagListFun,
      gridRef,
      container,
      icons,
      url: wechatApi.followedUrl,
      gridHeight: document.body.clientHeight - 479,
      updateList,
      columns: [
        {
          dataIndex: 'openId',
          title: 'openId',
          width: 210,
          ellipsis: true,
        },

        {
          dataIndex: 'remark',
          title: '备注',
          width: 160,
          ellipsis: true,
        },
        {
          dataIndex: 'tagIdList',
          title: '标签数量',
          width: 80,
          ellipsis: true,
          slots: { customRender: 'tagIdList' },
        },
        {
          dataIndex: 'wxTagList',
          title: '标签',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'wxTagList' },
        },
        {
          dataIndex: 'subscribe',
          title: '关注状态',
          width: 80,
          ellipsis: true,
          slots: { customRender: 'subscribe' },
        },
        {
          dataIndex: 'subscribeTime',
          title: '关注时间',
          width: 200,
          ellipsis: true,
        },
        // {
        //   key: 'id',
        //   title: '操作',
        //   fixed: 'right',
        //   width: 160,
        //   slots: { customRender: 'operation' },
        // },
      ],
      btnOperation: [
        {
          type: 'blacklist',
          label: '加入黑名单',
          permission: 'resources:add',
          fnClick: () => {
            console.log('state.selectedRowKeys', state.selectedRowKeys);
            if (state.selectedRowKeys.length > 0) {
              Modal.confirm({
                title: `确认加入黑名单吗？`,
                icon: createVNode(icons['ExclamationCircleOutlined']),
                okType: '',
                okText: '确认',
                onOk: () => {
                  //   加入黑名单接口
                  let data = {
                    appId: state.appId,
                    idList: state.selectedRowKeys,
                  };
                  wechatApi
                    .membersBatchLackList({}, data)
                    .then(res => {
                      if (res) {
                        message.success('成功加入黑名单');
                        gridRef.value.refreshGrid();
                      }
                    })
                    .catch(err => {
                      console.log('error', err);
                      // message.error('网络异常，请联系技术人员查看')
                    });
                },
              });
            } else {
              message.info('请先勾选粉丝用户');
            }
          },
        },
      ],
      handleClickSueess,
      handletagIdQuery(record) {
        // 打开标签弹窗
        console.log('record', record);
        state.tabId = record.id;
        state.showTab = true;
      },
      handleClickByMenu() {},

      handleItem(item) {
        state.itemId = item.id;
        state.tagId = item.tagId;

        // state.wxTagList.map((i)=>{
        //   if (item.id === i.id) {
        //     i.color = !item.color
        //   }
        // })
      },
      updateContactFun() {
        context.emit('updateFanStatus');
        getWxTagListFun();
      },

      closeTab(item, record) {
        // console.log('closeTab', item, record)

        Modal.confirm({
          title: `确定删除此用户标签吗？`,
          icon: createVNode(icons['ExclamationCircleOutlined']),
          okType: '',
          okText: '确认',
          onOk: () => {
            let data = {
              appId: state.appId,
              idList: [record.id],
              tagId: item.tagId,
            };
            wechatApi
              .membersUnBatchTagging({}, data)
              .then(res => {
                if (res) {
                  // 开启Loading状态
                  message.success('标签删除成功');
                  gridRef.value.refreshGrid();
                }
              })
              .catch(err => {
                console.log('error', err);
                // message.error('网络异常，请联系技术人员查看')
              });
          },
        });
      },
    };
  },
};
</script>

<style scoped lang="less">
.issGridWrap {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;

  .left {
    width: 30%;
    border-radius: 6px;
  }
  .right {
    width: 70%;
    margin-left: 10px;
    position: relative;
    .refresh-btn {
      position: absolute;
      right: 20px;
      top: 2px;
      cursor: pointer;

      .updata {
        margin-left: 10px;
      }
    }
  }
}

.tabContent {
  border: 1px solid #d8d8d8;
  padding: 18px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  height: calc(100vh - 380px);
  max-height: calc(100vh - 380px);
  overflow: auto;

  .tabItem {
    border-radius: 4px;
    //padding: 4px 12px;
    padding: 4px 5px;
    color: #333333;
    border: 1px solid #333333;
    //border: 1px solid #999999;

    display: flex;
    justify-content: center;
    align-items: center;
    //width: 100px;
    width: 100px;
  }
  .selecItemClass {
    color: #5979f8;
    border: 1px solid #5979f8;
  }
}
.titleTab {
  display: flex;
  justify-content: space-between;
  align-content: center;
  .tabLeft {
    color: #333333;
  }
  .tabRight {
  }
}

.issGridWrap
  /deep/
  ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.ant-dropdown-content {
  padding: 14px 0px 0px 8px !important;
}

.errorList {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 10px;

  .opendId {
  }
}

//.right /deep/ ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.ant-dropdown-content {
//  padding: 14px 0px 14px 10px !important;
//}

//display: flex;
//flex-wrap: wrap;
//justify-content: flex-start;
//max-height: 300px;
//overflow: auto;

.right /deep/.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-height: 300px;
  overflow: auto;
}

.right /deep/.ant-dropdown-menu {
  padding: 16px 0px 16px 8px;
}
//.right /deep/ .ant-dropdown .ant-dropdown-placement-bottomCenter {
//  left: 300px !important;
//}

.menuClass {
  width: 420px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 150px;
  overflow: auto;
}

.menuItem {
  padding: 0px 4px 14px 0px !important;
}

.modalClass /deep/ .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
