'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/wechat/wechatSendTemplate/page',
  recordUrl: '/wechat/wechatSendTemplateBatchRecords/page',
  singleRecordUrl: '/wechat/wechatSendTemplateFansRecords/page',
  // 已关注列表
  followedUrl: '/wechat/wxContact/page',

  // 同步微信模板消息
  pullLatestTemplate(code, id) {
    return http.get(
      `/wechat/wechatMessageTemplate/pullLatestTemplate?appId=${id}`,
      { code }
    );
  },
  // 查询是否有微信消息模板同步记录
  isSyncRecordsByAppId(code, id) {
    return http.get(
      `/wechat/wechatMessageTemplateSyncRecords/isSyncRecordsByAppId?appId=${id}`,
      { code }
    );
  },
  // 分页查询微信模板消息表
  getTemplateList(code, params) {
    return http.get('/wechat/wechatMessageTemplate/page', { params, code });
  },

  // 分页查询模板消息发送批次记录表
  getWechatMessageRecordsList(code, params) {
    return http.get('/wechat/wechatSendTemplateBatchRecords/page', {
      params,
      code,
    });
  },
  // 根据租户查询appId
  getTenantId(code) {
    return http.get(`/wechat/wechatAccountChannel/genAppIdByTalent`, { code });
  },
  // 根据微信模板数据库ID查询微信模板消息
  getTemplateById(code, id) {
    return http.get(`/wechat/wechatMessageTemplate/${id}`, { code });
  },
  // 根据微信模板ID查询微信模板消息(编辑)
  getTemplateByWechatId(code, params) {
    return http.get(`/wechat/wechatMessageTemplate/getTemplateById`, {
      params,
      code,
    });
  },
  // 查询发送消息模板表（编辑）
  getSendTemplateById(code, params) {
    return http.get(
      `/wechat/wechatSendTemplate/${params.id}?appId=${params.appId}`,
      { code }
    );
  },
  // 新增发送消息模板表
  addTemplateList(code, data) {
    return http.post('/wechat/wechatSendTemplate', data, { code });
  },
  // 编辑发送消息模板表
  editTemplateList(code, data) {
    return http.put('/wechat/wechatSendTemplate', data, { code });
  },
  // 复制模版消息
  copyTemplateList(code, params) {
    return http.get('/wechat/wechatSendTemplate/copySendTemplate', {
      params,
      code,
    });
  },
  // 删除模版消息
  deleteTemplateList(code, params) {
    return http.delete('/wechat/wechatSendTemplate', { params, code });
  },
  // 查询标签列表数据
  getWxTagList(code, id) {
    return http.get(`/wechat/wxTag/wxTagList?appId=${id}`, { code });
  },
  //  判断是否达到上限
  isAchieveSendThreshold(code, data) {
    return http.post(
      '/wechat/wechatSendTemplateBatchRecords/isAchieveSendThreshold',
      data,
      { code }
    );
  },
  //发送模版消息
  sendTemplateBatchRecords(code, data) {
    return http.post('/wechat/wechatSendTemplateBatchRecords', data, { code });
  },
  // 取消发送
  cancelTemplateBatchRecords(code, data) {
    return http.put('/wechat/wechatSendTemplateBatchRecords', data, { code });
  },

  // 数据看板

  // 统计当前appId发送数量
  sendMessageTemplateCount(code, params) {
    return http.get(
      `/wechat/wechatSendTemplateFansRecords/sendMessageTemplateCount`,
      { params, code }
    );
  },

  // 统计当前appId下用户点击次数
  contactOpenClickCount(code, params) {
    return http.get(
      `/wechat/wechatSendTemplateFansRecords/contactOpenClickCount`,
      { params, code }
    );
  },

  // 导出批次发送记录

  exportTemplateFansRecords(code, params) {
    return http.post(
      '/wechat/wechatSendTemplateFansRecords/exportTemplateFansRecords',
      params,
      { responseType: 'blob', code }
    );
  },

  // 查询模板消息粉丝发送单条记录数据信息
  getTemplateRecords(code, params) {
    return http.get(
      `/wechat/wechatSendTemplateFansRecords/getFansRecordsCount`,
      { params, code }
    );
  },

  //  批量为用户打标签
  membersBatchTagging(code, data) {
    return http.post('/wechat/wxContact/membersBatchTagging', data, { code });
  },
  // 批量添加用户加入黑名单
  membersBatchLackList(code, data) {
    return http.post('/wechat/wxContact/membersBatchLackList', data, { code });
  },
  //   批量为用户取消打标签
  membersUnBatchTagging(code, data) {
    return http.post('/wechat/wxContact/membersUnBatchTagging', data, { code });
  },

  // 批量移除黑名单

  removeBlack(code, data) {
    return http.post('/wechat/wxContact/membersBatchUnBlackList', data, {
      code,
    });
  },
  // 查询更新记录最后时间

  getUpdateTime(code, id) {
    return http.get(`/wechat/wxUpdateContactRecord/getUpdateTime/${id}`, {
      code,
    });
  },
  // 更新粉丝状态
  updateContact(code, params) {
    return http.get(`/wechat/wxContact/updateContact`, { params, code });
  },
  // 查询批量打标签结果
  membersBatchResult(code, id) {
    return http.get(`/wechat/wxContact/membersBatchResult/${id}`, { code });
  },
  //   空白页面采集点击行为
  saveMongoDbContactOpenRecord(code, params) {
    return http.get(
      `/wechat/wechatContactOpenTemplateRecords/saveMongoDbContactOpenRecord`,
      { params, code }
    );
  },
  //   获取微信签名
  getWechatSign(code, params) {
    return http.get(`/form/formData/getWechatSign`, { params, code });
  },
};
